import React from 'react'
import { createRoot } from 'react-dom/client'
import 'typeface-roboto'
import { datadogRum } from '@datadog/browser-rum'

import './index.css'
import App from './components/App'
import {
  SENTRY_DSN,
  APP_VERSION,
  SENTRY_ENVIRONMENT,
  RUM_APP_ID,
  RUM_CLIENT_TOKEN,
} from './constants'
import { isDev } from './utils/helpers'
import './i18n'

  // Dynamically load Sentry SDK
  ; (async () => {
    const Sentry = await import('@sentry/react')
    const { BrowserTracing } = await import('@sentry/tracing')
    if (!isDev) {
      Sentry.init({
        dsn: SENTRY_DSN,
        release: APP_VERSION,
        environment: SENTRY_ENVIRONMENT,
        integrations: [new BrowserTracing(), new Sentry.Replay()],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      })
      Sentry.configureScope(scope => {
        scope.setTag('origin', document.referrer)
      })
    }
  })()

datadogRum.init({
  applicationId: RUM_APP_ID,
  clientToken: RUM_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'finerconnect-map',
  env: SENTRY_ENVIRONMENT,
  version: APP_VERSION,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
})

datadogRum.startSessionReplayRecording()
console.debug('finderCONNECT version:', APP_VERSION)
const rootElement = document.getElementById('root')
const root = createRoot(rootElement)
root.render(<App />)
